<template>
  <div class="tw-mb-8">
    <h3>{{ title }}</h3>
    <template v-if="candidates.length">
      <div v-for="(candidateData, index) in candidates" :key="index" class="tw-mb-2">
        <strong>{{ candidateData.candidate_name }}</strong>
        <ul v-for="(activity, index) in candidateData.activities" :key="index">
          <li>
            {{ activity.type.name_nl }} ({{ activity.start_date }}): <i>{{ activity.feedback }}</i>
          </li>
        </ul>
      </div>
    </template>
    <p v-else>Geen kandidaten beschikbaar.</p>
  </div>
</template>

<script>
export default {
  name: 'OwnerReportCandidates',
  props: {
    title: {
      type: String
    },
    candidates: {
      type: Array,
      default: Object
    }
  }
}
</script>
