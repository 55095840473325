import { httpClient } from './axiosClients'

export function getOwnerReportDetail (reportId) {
  return httpClient.get(`/api/owner-reports/${reportId}/detail`)
}

export function getOwnerReportPreview (reportId) {
  return httpClient.get(`/api/owner-reports/${reportId}/preview`)
}

export function emailOwnerReport (reportId) {
  return httpClient.get(`/api/owner-reports/${reportId}/email`)
}

export function deleteOwnerReport (reportId) {
  return httpClient.delete(`/api/owner-reports/${reportId}`)
}

// We provide an empty object as default value to payload because we de-structure it later on
export function getPropertyOwnerReports (payload = {}) {
  const { propertyId, url = `/api/property/${propertyId}/owner-reports`, params } = payload
  return httpClient.get(url, { params })
}

export function getProjectOwnerReports (payload = {}) {
  const { projectId, url = `/api/project/${projectId}/owner-reports`, params } = payload
  return httpClient.get(url, { params })
}

export function exportOwnerReportCommunication (reportId) {
  return httpClient.post(`/api/owner-reports/${reportId}/export`)
}

export function getOwnerReportCommunicationExportStatus (jobID) {
  return httpClient.get(`/api/owner-reports/export-jobs/${jobID}/detail`)
}

// New owner report URLs
export function getPropertyOwnerReportsNew (payload = {}) {
  const { propertyId, url = `/api/properties/${propertyId}/owner-reports`, params } = payload
  return httpClient.get(url, { params })
}

export function getProjectOwnerReportsNew (payload = {}) {
  const { projectId, url = `/api/projects/${projectId}/owner-reports`, params } = payload
  return httpClient.get(url, { params })
}

export function getPropertyOwnerReportData (payload) {
  const { id, url = `/api/properties/${id}/owner-report-data`, params } = payload
  return httpClient.get(url, { params })
}

export function getProjectOwnerReportData (payload) {
  const { id, url = `/api/projects/${id}/owner-report-data`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyOwnerReport (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/owner-reports`, payload)
}

export function createProjectOwnerReport (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/owner-reports`, payload)
}

export function sendPropertyOwnerReportViaEmail (propertyId, ownerReportId) {
  return httpClient.post(`/api/properties/${propertyId}/owner-reports/${ownerReportId}/email`)
}

export function sendProjectOwnerReportViaEmail (projectId, ownerReportId) {
  return httpClient.post(`/api/projects/${projectId}/owner-reports/${ownerReportId}/email`)
}

export function deletePropertyOwnerReport (propertyId, ownerReportId) {
  return httpClient.delete(`/api/properties/${propertyId}/owner-reports/${ownerReportId}`)
}

export function deleteProjectOwnerReport (projectId, ownerReportId) {
  return httpClient.delete(`/api/projects/${projectId}/owner-reports/${ownerReportId}`)
}

export function exportPropertyOwnerReportCandidates (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/export-owner-report-candidates`, payload)
}

export function exportProjectOwnerReportCandidates (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/export-owner-report-candidates`, payload)
}

export function getCandidatesExportStatus (jobId) {
  return httpClient.get(`/api/owner-reports/candidates-export-jobs/${jobId}`)
}
